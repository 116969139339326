export const SALES_PACKAGE_BASE_WITH_COMPONENT_FRAGMENT = `
  fragment salesPackageBaseWithComponentFragment on SalesPackage {
    __typename
    uid
    title
    includedByDefault
    _isPurchased(exhibitorUid: %exhibitorUid%)
    salesPackageComponent {
      uid
      salesComponent {
        uid
        id
      }
    }
  }
`;
